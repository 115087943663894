import React from 'react';

import './styles.scss';

function LoadingSpinner() {
    return (
        <div className="LoadingSpinner">Loading...</div>
    );
}

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
