import React from 'react';
import {Carousel} from 'react-bootstrap';
import './styles.css';
import PropTypes from "prop-types";

class AttractionCarousel extends React.Component {
    render() {
        return (
            <Carousel className="AttractionCarousel">
                {this.props.images.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image.url}
                                alt="Attraction Img"
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        );
    }
}

AttractionCarousel.defaultProps = {
    images: [],
};

AttractionCarousel.propTypes = {
    images: PropTypes.array,
};

export default AttractionCarousel;
