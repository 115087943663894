import React from 'react';
import {isEmpty} from 'lodash';
import auth from '../../Utils/auth';
import './styles.css';

/**
 * @return {null}
 */
function LoggedInUserNavMenu() {
    if (!isEmpty(auth.getToken())) {
        const userInfo = auth.getUserInfo();

        return (<li className="nav-item dropdown dropdown-slide">
            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/profile">
                {userInfo.username}<span><i className="fa fa-angle-down"></i></span>
            </a>

            <div className="dropdown-menu">
                <a className="dropdown-item" href="/reservations">Reservas</a>
                <a className="dropdown-item" href="/restaurants">Restaurants</a>
                <a className="dropdown-item" href="/profile">Mis Datos</a>
            </div>
        </li>)
    } else {
        return null
    }
}

export default LoggedInUserNavMenu;
