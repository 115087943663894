import React from 'react';
import './styles.css';
import PropTypes from "prop-types";

class UserReservation extends React.Component {
    render() {
        return (
            <section className="UserReservation">
                <h2>{this.props.reservation.title}</h2>
                <pre>{JSON.stringify(this.props.reservation, undefined, 2)}</pre>

            </section>
        );
    }
}

UserReservation.defaultProps = {
    reservation: {},
};

UserReservation.propTypes = {
    reservation: PropTypes.array,
};
export default UserReservation;
