import React from 'react';
import './styles.css';

class ActivityOfferPrice extends React.Component {

    render() {
        return (
            <section className="ActivityOfferPrice">
                <span>$ {this.props.offer.price}</span>
            </section>
        );
    }
}

export default ActivityOfferPrice;
