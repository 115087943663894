import React from 'react';
import './styles.scss';
import Logo from "../../Assets/logo-footer.png";

function Footer() {
    return (
        <footer className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-4 pb-3 my-md-3  text-white ">
                        <div className="row">
                            <div className="col-12 col-md">
                                <h4 className="text-white mb-1">Turisca</h4>
                                <ul className="list-unstyled text-small">
                                    <li><a className="text-white" href="/">Quienes somos</a></li>
                                    <li><a className="text-white" href="/">Destinos</a></li>
                                </ul>
                            </div>
                            <div className="col-6 col-md">
                                <h4 className="text-white mb-1">Ayuda</h4>
                                <ul className="list-unstyled text-small">
                                    <li><a className="text-white" href="/">Preguntas frecuentes</a></li>
                                    <li><a className="text-white" href="/">Contacto</a></li>
                                </ul>
                            </div>
                            <div className="col-6 col-md">
                                <h4 className="text-white mb-1">Potenciá tu negocio</h4>
                                <ul className="list-unstyled text-small">
                                    <li><a className="text-white" href="/">Súmate</a></li>
                                </ul>
                            </div>
                            <div className="col-6 col-md-4">
                                <h4 className="text-white">Recibí ofertas exclusivas en tu email</h4>
                                <form className="form-inline">
                                    <div className="form-group mb-2">
                                        <input type="email" className="form-control form-control-md" id="exampleFormControlInput1"
                                               placeholder="Ingresa tu E-mail"/>
                                    </div>
                                    <button type="submit" className="btn btn-secondary mb-2 ml-3">Recibir</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="border-top "></div>
            <div className="container">
                        <div className="col-12 pt-2 pb-3 ">
                        <div className="row">
                        <div className="col-4 col-md-4">
                            <img src={Logo} alt=""/>
                        </div>
                        <div className="col-8 col-md-8">
                            <p><small>Copyright © <script>
                                var CurrentYear = new Date().getFullYear()
                                document.write(CurrentYear)
                            </script>2019. All Rights Reserved.</small></p>
                        </div>
                        </div>
                        </div>
            </div>


        </footer>
    );
}

Footer.propTypes = {};

export default Footer;
