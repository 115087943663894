import React from 'react';
import PropTypes from 'prop-types';
import {findIndex, get, map, replace, set} from 'lodash';
import {Link} from 'react-router-dom';

import Button from '../../Components/Button';
import FormDivider from '../../Components/FormDivider';
import Input from '../../Components/InputsIndex';
import SocialLink from '../../Components/SocialLink';
// Utils
import auth from '../../Utils/auth';
import request from '../../Utils/request';

import form from './forms.json';
import './styles.scss';

class AuthPage extends React.Component {

    state = {value: {}, errors: [], didCheckErrors: false};

    componentDidMount() {
        this.generateForm(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.authType !== this.props.match.params.authType) {
            this.generateForm(nextProps);
        }
    }

    getRequestURL = () => {
        let requestURL;

        switch (this.props.match.params.authType) {
            case 'login':
                requestURL = process.env.REACT_APP_API_URL + '/auth/local';
                break;
            case 'register':
                requestURL = process.env.REACT_APP_API_URL + '/auth/local/register';
                break;
            case 'reset-password':
                requestURL = process.env.REACT_APP_API_URL + '/auth/reset-password';
                break;
            case 'forgot-password':
                requestURL = process.env.REACT_APP_API_URL + '/auth/forgot-password';
                break;
            default:
        }

        return requestURL;
    };

    generateForm = props => {
        const params = props.location.search
            ? replace(props.location.search, '?code=', '')
            : props.match.params.id;
        this.setForm(props.match.params.authType, params);
    };

    handleChange = ({target}) =>
        this.setState({
            value: {...this.state.value, [target.name]: target.value},
        });

    handleSubmit = e => {
        e.preventDefault();
        const body = this.state.value;
        const requestURL = this.getRequestURL();

        // This line is required for the callback url to redirect your user to app
        if (this.props.match.params.authType === 'forgot-password') {
            set(body, 'url', process.env.REACT_APP_API_URL + '/auth/reset-password');
        }

        request(requestURL, {method: 'POST', body: this.state.value})
            .then(response => {
                auth.setToken(response.jwt, body.rememberMe);
                auth.setUserInfo(response.user, body.rememberMe);
                this.redirectUser();
            })
            .catch(err => {
                // TODO handle errors for other views
                // This is just an example
                const errors = [
                    {name: 'identifier', errors: [err.response.payload.message]},
                ];
                this.setState({didCheckErrors: !this.state.didCheckErrors, errors});
            });
    };

    redirectUser = () => {
        this.props.history.push('/');
    };

    /**
     * Function that allows to set the value to be modified
     * @param {String} formType the auth view type ex: login
     * @param {String} email    Optionnal
     */
    setForm = (formType, email) => {
        const value = get(form, ['data', formType], {});

        if (formType === 'reset-password') {
            set(value, 'code', email);
        }

        this.setState({value});
    };

    /**
     * Check the URL's params to render the appropriate links
     * @return {Element} Returns navigation links
     */
    renderLink = () => {
        if (this.props.match.params.authType === 'login') {
            return (
                <div>
                    <Link to="/auth/forgot-password">¿Has olvidado tu contraseña?</Link>

                    <div className="col-12">
                      <Link to="/auth/register"  className="text-secondary">¿No tienes cuenta?  <span className="text-secondary"> Crear una cuenta</span></Link>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Link to="/auth/login">Ready to signin</Link>
            </div>
        );
    };

    render() {
        const divStyle =
            this.props.match.params.authType === 'register'
                ? {marginTop: '3.2rem'}
                : {marginTop: '.9rem'};
        const inputs = get(form, ['views', this.props.match.params.authType], []);
        const providers = ['facebook', 'google']; // To remove a provider from the list just delete it from this array...

        return (
            <section className="authPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="authFormContainer">
                                <div className="headerContainer">
                                    {this.props.match.params.authType === 'register' ? (
                                        <span>Welcome !</span>
                                    ) : ('')}
                                </div>
                                <div className="headerDescription">
                                    {this.props.match.params.authType === 'register' ? (
                                        <span>Please register to access the app.</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="formContainer" style={divStyle}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {providers.map(provider => (
                                                    <SocialLink provider={provider} key={provider}/>
                                                ))}
                                            </div>
                                        </div>
                                        <FormDivider/>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row" style={{textAlign: 'start'}}>
                                                {map(inputs, (input, key) => (
                                                    <Input
                                                        autoFocus={key === 0}
                                                        customBootstrapClass={get(input, 'customBootstrapClass')}
                                                        didCheckErrors={this.state.didCheckErrors}
                                                        errors={get(
                                                            this.state.errors,
                                                            [
                                                                findIndex(this.state.errors, ['name', input.name]),
                                                                'errors',
                                                            ],
                                                            []
                                                        )}
                                                        key={get(input, 'name')}
                                                        label={get(input, 'label')}
                                                        name={get(input, 'name')}
                                                        onChange={this.handleChange}
                                                        placeholder={get(input, 'placeholder')}
                                                        type={get(input, 'type')}
                                                        validations={{required: true}}
                                                        value={get(this.state.value, get(input, 'name'), '')}
                                                    />
                                                ))}
                                                <div className="col-md-12 buttonContainer">
                                                    <Button
                                                        label="Submit"
                                                        style={{width: '100%'}}
                                                        primary
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="linkContainer">{this.renderLink()}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default AuthPage;
