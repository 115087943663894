import React from 'react';
import './styles.css';
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";
import auth from "../../Utils/auth";
import PropTypes from "prop-types";
import UserReservation from "../../Components/UserReservation";

class ReservationsPage extends React.Component {
    state = {reservations: []};

    componentWillMount() {
        const currentUser = auth.getUserInfo();
        request(`${process.env.REACT_APP_API_URL}/activityreservations/?client._id=${currentUser._id}`, {method: 'GET'})
            .then((responseJson) => {
                this.setState({reservations: responseJson})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        return (
            <section className="ReservationsPage">
                {this.state.reservations.length ? this.state.reservations.map((reservation, index) => {
                    return (
                        <UserReservation key={index} reservation={reservation}/>
                    )
                }) : <LoadingSpinner/>}
            </section>
        );
    }
}

ReservationsPage.defaultProps = {
    reservations: [],
    onClick: () => {
    },
};

ReservationsPage.propTypes = {
    reservations: PropTypes.array,
    onClick: PropTypes.func,
};

export default ReservationsPage;
