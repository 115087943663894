import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import './App.scss';
import history from './Utils/history'
import HomePage from './Pages/HomePage';
import AuthPage from './Pages/AuthPage';
import FooterBig from "./Components/FooterBig";
import Footer from "./Components/Footer";
import PrivateRoute from './Containers/PrivateRoute';
import RestaurantPage from "./Pages/RestaurantPage";
import EditPage from "./Pages/EditPage";
import NotFoundPage from "./Pages/NotFoundPage";
import ActivityPage from "./Pages/ActivityPage";
import ReservationPage from "./Pages/ReservationPage";
import AttractionPage from "./Pages/AttractionPage";
import UserProfilePage from "./Pages/UserProfilePage";
import ReservationsPage from "./Pages/ReservationsPage";
import NavBar from "./Components/NavBar";

function App() {
    document.title = 'Turisca.com - Tours, Actividades, Paseos.';
    return (
        <Router history={history}>
            <NavBar/>
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route path="/auth/:authType/:id?" component={AuthPage}/>
                <Route path="/activity/:id" component={ActivityPage}/>
                <Route path="/attraction/:id" component={AttractionPage}/>
                <PrivateRoute path="/reservations" component={ReservationsPage}/>
                <PrivateRoute path="/reservation/:id" component={ReservationPage}/>
                <PrivateRoute path="/profile" component={UserProfilePage}/>
                <PrivateRoute exact path="/restaurants" component={RestaurantPage}/>
                <PrivateRoute path="/:contentType/:id" component={EditPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
            <FooterBig/>
            <Footer/>
        </Router>
    );
}

export default App;
