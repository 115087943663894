import React from 'react';
import './styles.css';
import PropTypes from "prop-types";
import StarRatingComponent from 'react-star-rating-component';

class ActivityComments extends React.Component {
    render() {
        return (
            <section className="ActivityComments">
                <h2>Comentarios</h2>
                {this.props.comments.map((comment, index) => {
                    return (
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Valoraciôn: {comment.valuation}/5</h5>
                                <StarRatingComponent
                                    starCount={5}
                                    editing={false}
                                    value={comment.valuation}
                                />
                                <h5 className="card-title">{comment.author_name}</h5>
                                <p className="card-text">"{comment.body}"</p>
                            </div>
                        </div>
                    )
                })}
            </section>
        );
    }
}

ActivityComments.defaultProps = {
    comments: {},
};

ActivityComments.propTypes = {
    comments: PropTypes.object,
};

export default ActivityComments;
