import React from 'react';
import './styles.css';
import ActivityOfferPrice from "../ActivityOfferPrice";

class ActivityOffers extends React.Component {
    render() {
        return (
            <section className="ActivityOffers">
                <h2>Desde</h2>
                {this.props.offers.map((offer, index) => {
                    return (
                        <ActivityOfferPrice key={index} offer={offer}/>
                    )
                })}
            </section>
        );
    }
}

export default ActivityOffers;
