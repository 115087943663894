import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CardWithTitleAndIntroText = (props) => {
    return (
        <div className="col-md-4 CardWithTitleAndIntroText">
            <div className="card">
                <img className="card-img-top" src={props.data.main_image.url} alt={props.data.title}/>
                <div className="card-body">
                    <h5 className="card-title"
                        onClick={(e) => {
                            // Handle navigation to EditPage container
                            e.preventDefault();
                            props.onClick(props.data.id);
                        }}>{props.data.title}</h5>
                    <p className="card-text">{props.data.intro}</p>
                </div>
            </div>
        </div>
    );
};

CardWithTitleAndIntroText.defaultProps = {
    data: {},
    onClick: () => {
    },
};

CardWithTitleAndIntroText.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};
export default CardWithTitleAndIntroText;
