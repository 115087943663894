import React from 'react';
import './styles.css';
import AttractionsBlock from "../../Containers/AttractionsBlock";
import WhatToDoBlock from "../../Containers/WhatToDoBlock";
import FeaturedActivitiesBlock from "../../Containers/FeaturedActivitiesBlock";

class HomePage extends React.Component {

    render() {
        return (
            <section className="homePage">
                <div className="container">
                    <FeaturedActivitiesBlock/>
                    <WhatToDoBlock/>
                    <AttractionsBlock/>
                </div>
            </section>
        );
    }
}

export default HomePage;
