/**
 *
 * NotFoundPage
 * This is the component that will show when you have a 404
 */

import React from 'react';

function NotFoundPage(props) {
    return (
        <div>
            <p>The page you're looking for doesn't exist.</p>
        </div>
    );
}

export default NotFoundPage;
