import React from 'react';
import './styles.css';
import request from "../../Utils/request";
import CardWithTitleAndIntroText from "../../Components/CardWithTitleAndIntroText";
import history from "../../Utils/history";
import LoadingSpinner from "../../Components/LoadingSpinner";

class WhatToDoBlock extends React.Component {
    state = {attractions: []};

    componentWillMount() {
        request(`${process.env.REACT_APP_API_URL}/attractions?_limit=3&_sort=createdAt:desc`, {method: 'GET'})
            .then((responseJson) => {
                this.setState({attractions: responseJson});
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onClick = (id) => {
        history.push(`/attraction/${id}`);
    };

    renderDetails(attractions) {
        return (
            <div className="d-flex align-content-start flex-wrap">
                {attractions.map((attraction, index) => {
                    return (
                        <CardWithTitleAndIntroText key={index} data={attraction} onClick={this.onClick}/>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <section className="whatToDoBlock">
                <h2 className="text-primary text-center mt-5">Que hacer</h2>
                {this.state.attractions.length ? this.renderDetails(this.state.attractions) : <LoadingSpinner/>}
            </section>
        );
    }
}

export default WhatToDoBlock;
