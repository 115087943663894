import React from 'react';
import './styles.scss';
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ActivityOffers from "../../Components/ActivityOffers";
import ActivityCheckAvailability from "../../Containers/ActivityCheckAvailability";
import ActivityComments from "../../Components/ActivityComments";
import ActivityCarousel from "../../Components/ActivityCarousel";
import Markdown from "react-markdown";
import ActivityDetails from "../../Components/ActivityDetails";

class ActivityPage extends React.Component {
    state = {activity: null};

    componentWillMount() {
        const {match: {params}} = this.props;
        request(`${process.env.REACT_APP_API_URL}/activities/${params.id}`, {method: 'GET'})
            .then((responseJson) => {
                this.setState({activity: responseJson})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    renderDetails(activity) {
        console.log("Activity:", activity);
        return (
            <div>
                <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page"><a href="/"> <i className="fas fa-chevron-left pr-1"></i> Argentina</a></li>
                    </ol>
                </nav>
                {
                    activity.images && activity.images.length > 0
                        ? <ActivityCarousel images={activity.images}/>
                        : <img className="activityMainImage img-fluid mx-auto d-block px-3"
                               src={activity.main_image.url}
                               alt={activity.title}/>
                }

                    <div className="activity-attraction-columns">
                        <nav className="navbar navbar-expand-lg navbar-white row">
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarText">
                                <ul className="navbar-nav  nav-info mr-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link text-secondary" href="/">Descripción<span
                                            className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Información general</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Precio</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Lugar de encuentro</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Comentarios</a>
                                    </li>
                                </ul>
                                <span className="navbar-text nav-info">
                            <a className="nav-link" href="/"><i className="fas fa-share-alt mr-2"></i>Compartir </a>
                        </span>
                                <span className="navbar-text nav-info">
                          <a className="nav-link" href="/"><i className="far fa-heart mr-2"></i> Guardar</a>
                        </span>
                            </div>
                        </nav>

                    <h1>{activity.title}</h1>
                    <h4>{activity.intro}</h4>
                    <Markdown source={activity.description}/>
                    <ActivityDetails activity={activity}/>
                    {activity.activityOffers.length ? <ActivityOffers offers={activity.activityOffers}/> : ''}
                    <ActivityCheckAvailability activity={activity}/>
                    {activity.comments.length ? <ActivityComments comments={activity.comments}/> : ''}
                    <pre>{JSON.stringify(activity, undefined, 2)}</pre>
                </div>
            </div>
            </div>
        )
    }

    render() {
        return (
            <section className="ActivityPage">
                {this.state.activity ? this.renderDetails(this.state.activity) : <LoadingSpinner/>}
            </section>
        );
    }
}

export default ActivityPage;
