import React from 'react';
import './styles.css';
import LoadingSpinner from "../../Components/LoadingSpinner";
import auth from "../../Utils/auth";

class UserProfilePage extends React.Component {
    state = {userProfile: null};

    componentWillMount() {
        this.setState({userProfile: auth.getUserInfo()})
    }

    renderDetails(userProfile) {
        console.log("userProfile", userProfile);
        return (
            <div className="container">
                <h3>{userProfile.username}</h3>
                <pre>{JSON.stringify(userProfile, undefined, 2)}</pre>
            </div>
        )
    }

    render() {
        return (
            <section className="UserProfilePage">
                {this.state.userProfile ? this.renderDetails(this.state.userProfile) : <LoadingSpinner/>}
            </section>
        );
    }
}

export default UserProfilePage;
