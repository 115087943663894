import React from 'react';
import './styles.scss';

function FooterBig() {
    return (
        <footer className="footer section section-sm">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mt-5 mb-5">
                        <h1 className="text-white mb-0">Ofrece una experiencia en Turisca</h1>
                        <h3 className="text-white mt-0">Gana dinero organizando tus actividades favoritas</h3>
                        <div className="pt-2 pb-2">
                            <button type="button" className="btn btn-primary btn-lg">Ofrece una experiencia</button>
                            <button type="button" className="btn btn-outline-secondary btn-lg">Mas informacion</button>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

FooterBig.propTypes = {};

export default FooterBig;
