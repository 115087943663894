import React from 'react';
import {isEmpty} from 'lodash';
import auth from '../../Utils/auth';
import './styles.css';
import {Link} from "react-router-dom";

function LoginLogoutLink() {

    function showLoginLink() {
        return (
            <Link className="nav-link" to="/auth/login">Login</Link>
        )
    }

    function showLogoutLink() {
        return (
            <Link
                className="nav-link"
                to="/auth/logout"
                onClick={() => {
                    auth.clearAppStorage();
                    window.location.replace('/auth/login');
                }}>Logout</Link>
        )
    }

    if (isEmpty(auth.getToken())) {
        return (showLoginLink())
    } else {
        return (showLogoutLink())
    }
}

export default LoginLogoutLink;
