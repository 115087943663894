import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Table from '../../Components/Table';
// Utils
import request from '../../Utils/request';

import './styles.scss';

class RestaurantPage extends React.Component {
    state = {restaurants: []};

    async componentDidMount() {
        const requestURL = process.env.REACT_APP_API_URL + '/restaurants';

        const restaurants = await request(requestURL, {method: 'GET'});
        this.setState({restaurants});
    }

    onClick = (id) => {
        this.props.history.push(`/restaurants/${id}`);
    };

    render() {
        return (
            <div className="restaurantPageWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h1>restaurants</h1>
                        </div>
                        <div className="col-md-4 offset-md-4 restaurantPageLink">
                            <Link to="/restaurants/create">Create a restaurant</Link>
                        </div>
                    </div>
                    <div className="row">
                        <Table
                            data={this.state.restaurants}
                            headers={['id', 'name', 'images', '']}
                            onClick={this.onClick}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

RestaurantPage.defaultProps = {};

RestaurantPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default RestaurantPage;
