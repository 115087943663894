import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import auth from '../../Utils/auth';
import history from "../../Utils/history";
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";
import PropTypes from "prop-types";

class BookNowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationDate: new Date(),
            reservation: {},
            fetchInProgress: false,
            hasReservation: false,
        };
        this.handleBookNowSubmit = this.handleBookNowSubmit.bind(this);

    }

    handleBookNowSubmit(event) {
        event.preventDefault();
        this.setState({fetchInProgress: true});
        const userInfo = auth.getUserInfo();
        console.warn(this.props);
        const body = {
            title: this.props.activity.title + ' for ' + userInfo.username,
            client: userInfo.id,
            activity: this.props.activity.id,
            originalActivity: this.props.activity,
            details: {
                numberOfParticipants: this.props.numberOfParticipants,
                reservationDate: this.props.reservationDate.toISOString(),
            }
        };
        request(`${process.env.REACT_APP_API_URL}/activityreservations`, {method: 'POST', body: body})
            .then((responseJson) => {
                console.warn(responseJson);
                history.push(`/reservation/${responseJson.id}`);
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    fetchInProgress: false,
                    hasReservation: false,
                });
            });
    }

    renderButton() {
        return (
            <form onSubmit={this.handleBookNowSubmit}>
                <button type="submit" className="btn btn-primary">Reservar ahora</button>
            </form>
        )
    }

    render() {
        return (
            <section className="BookNowButton">
                {!this.state.fetchInProgress ? this.renderButton() : <LoadingSpinner/>}
            </section>
        );
    }
}

BookNowButton.defaultProps = {
    reservationDate: new Date(),
    numberOfParticipants: 1,
    activity: {},
};

BookNowButton.propTypes = {
    reservationDate: PropTypes.object,
    numberOfParticipants: PropTypes.number,
    activity: PropTypes.object,
};

export default BookNowButton;
