import React from 'react';
import './styles.scss';
import Logo from '../../Assets/logo.png';
import LoginLogoutLink from "../LoginLogoutLink";
import LoggedInUserNavMenu from "../LoggedInUserNavMenu";

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg  navbar-light fixed-top shadow bg-white py-1 navigation">
          <div className="container">
              <a className="navbar-brand mr-5" href="/">
                  <img className="d-inline-block align-top" src={Logo} alt=""/>
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse"
                      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <form className="form-inline">
                  <div className="input-group">
                      <input type="text" className="form-control" placeholder="¿A donde vamos?"
                             aria-label="¿A donde vamos?" aria-describedby="basic-addon2"/>
                      <div className="input-group-append">
                          <button className="btn btn-regular-radio btn-secondary mb-0" type="submit"><i className="fas fa-search"></i></button>
                      </div>
                  </div>
              </form>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">

                  <ul className="navbar-nav main-nav ml-auto">
                      <li className="nav-item">
                          <a className="nav-link" href="/"><i className="fas fa-shopping-cart"></i> Carrito</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/"><i className="fas fa-question-circle"></i>  Ayuda</a>
                      </li>
                      <li className="nav-item active">
                          <LoginLogoutLink/>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/">Registrarse</a>
                      </li>

                      <LoggedInUserNavMenu/>
                  </ul>
              </div>
            </div>
        </nav>
    );
}

NavBar.propTypes = {};

export default NavBar;
