import React from 'react';
import './styles.css';
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";

class ReservationPage extends React.Component {
    state = {activity: null};

    componentWillMount() {
        const {match: {params}} = this.props;
        request(`${process.env.REACT_APP_API_URL}/activityreservations/${params.id}`, {method: 'GET'})
            .then((responseJson) => {
                this.setState({reservation: responseJson})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    renderDetails(reservation) {
        console.log("Reserva:", reservation);
        return (
            <div className="container">
                <h3>{reservation.title}</h3>
                <pre>{JSON.stringify(reservation.details, undefined, 2)}</pre>
            </div>
        )
    }

    render() {
        return (
            <section className="ReservationPage">
                {this.state.reservation ? this.renderDetails(this.state.reservation) : <LoadingSpinner/>}
            </section>
        );
    }
}

export default ReservationPage;
