import React from 'react';
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";
import {Input} from 'semantic-ui-react'
import './styles.css';
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";
import BookNowButton from "../../Containers/BookNowButton";
import PropTypes from "prop-types";

class ActivityCheckAvailability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfParticipants: 1,
            reservationDate: new Date(),
            availability: {},
            fetchInProgress: false,
            hasAvailability: false,
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleParticipantsChange = this.handleParticipantsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);

    }

    handleDateChange(date) {
        this.setState({reservationDate: date});
    }

    handleParticipantsChange(participants) {
        this.setState({numberOfParticipants: participants});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({fetchInProgress: true});

        //@todo: Call Check-Availability API
        request(`${process.env.REACT_APP_API_URL}/attractions?_limit=6&_sort=createdAt:desc`, {method: 'GET'})
            .then((responseJson) => {
                this.setState(() => ({
                    availability: {
                        ...this.state.availability,
                        responseJson
                    },
                    fetchInProgress: false,
                    hasAvailability: true,
                }));

                // this.setState({
                //     availability: responseJson,
                //     fetchInProgress: false,
                //     hasAvailability: true,
                // });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    fetchInProgress: false,
                    hasAvailability: false,
                });
            });
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <DatePicker
                    selected={this.state.reservationDate}
                    onChange={this.handleDateChange}
                    minDate={new Date()}
                />
                <Input
                    icon='users'
                    iconPosition='left'
                    defaultValue={this.state.numberOfParticipants}
                    onChange={e => this.setState({numberOfParticipants: e.target.value})}
                    placeholder='Cantidad de personas...'
                    size='small'
                />
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        )
    }

    render() {
        console.info(this.props);
        return (
            <section className="ActivityCheckAvailability">
                <h6>Comprobar la disponibilidad</h6>
                <div className="form-check form-check-inline">
                    {!this.state.fetchInProgress ? this.renderForm() : <LoadingSpinner/>}
                    {this.state.hasAvailability ? <BookNowButton
                        activity={this.props.activity}
                        reservationDate={this.props.reservationDate}
                        numberOfParticipants={this.props.numberOfParticipants}/> : ''}
                </div>
            </section>
        );
    }
}

ActivityCheckAvailability.defaultProps = {
    activity: {},
};

ActivityCheckAvailability.propTypes = {
    activity: PropTypes.object,
};

export default ActivityCheckAvailability;
