import React from 'react';
import {Carousel} from 'react-bootstrap';
import './styles.css';
import PropTypes from "prop-types";

class ActivityCarousel extends React.Component {
    render() {
        return (
            <Carousel className="ActivityCarousel">
                {this.props.images.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image.url}
                                alt="Activity Img"
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        );
    }
}

ActivityCarousel.defaultProps = {
    images: [],
};

ActivityCarousel.propTypes = {
    images: PropTypes.array,
};

export default ActivityCarousel;
