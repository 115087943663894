import React from 'react';
import Markdown from "react-markdown";
import './styles.scss';
import request from "../../Utils/request";
import LoadingSpinner from "../../Components/LoadingSpinner";
import AttractionCarousel from "../../Components/AttractionCarousel";

class AttractionPage extends React.Component {

    state = {attraction: null};

    componentWillMount() {
        const {match: {params}} = this.props;
        request(`${process.env.REACT_APP_API_URL}/attractions/${params.id}`, {method: 'GET'})
            .then((responseJson) => {
                this.setState({attraction: responseJson})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    renderDetails(attraction) {
        console.log(attraction);
        return (
            <div>


                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page"><a href="/"> <i className="fas fa-chevron-left pr-1"></i> Argentina</a></li>
                        </ol>
                    </nav>
                {
                    attraction.images.length > 0
                        ? <AttractionCarousel images={attraction.images}/>
                        : <img className="img-fluid mx-auto d-block px-3" src={attraction.main_image.url}
                               alt={attraction.title}/>
                }

                <div className="activity-attraction-columns">
                    <h1 className="text-primary">{attraction.title}</h1>
                    <div className="rating">
                      <div className="rating-star-empty">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <div className="rating-star-full">

                      </div>
                    </div>

                    <h4>{attraction.intro}</h4>
                    <Markdown source={attraction.description}/>
                    <pre>{JSON.stringify(attraction, undefined, 2)}</pre>
                </div>
            </div>
            </div>
        )
    }

    render() {
        return (
            <section className="AttractionPage">
                {this.state.attraction ? this.renderDetails(this.state.attraction) : <LoadingSpinner/>}
            </section>
        );
    }
}

export default AttractionPage;
