import React from 'react';
import './styles.css';
import PropTypes from "prop-types";

class ActivityDetails extends React.Component {
    displayItems(items) {
        return items.map(function (item, index) {
            if (item.title && item.items && item.items.length > 0) {
                return (
                    <div key={index}>
                        <h4><span><i className={`fa ${item.icon}`}></i></span> {item.title}</h4>
                        <p>{item.text}</p>
                        <ul>
                            {item.items.map(function (item, index) {
                                return (
                                    <li>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                )
            } else if (item.title) {
                return (
                    <div key={index}>
                        <h4><span><i className={`fa ${item.icon}`}></i></span> {item.title}</h4>
                        <p>{item.text}</p>
                    </div>
                )
            }
            return (
                <div key={index}>
                    <p><span><i className={`fa ${item.icon}`}></i></span> {item.text}</p>
                </div>
            )
        });
    }

    render() {
        return (
            <div className="ActivityDetails">
                <div className="detailsBlock">
                    <h2>Información General</h2>
                    {this.displayItems(this.props.activity.general_information)}
                </div>

                <div className="detailsBlock">
                    <h2>La Experiencia</h2>
                    {this.displayItems(this.props.activity.the_experience)}
                </div>

                <div className="detailsBlock">
                    <h2>Preparate</h2>
                    {this.displayItems(this.props.activity.get_ready)}
                </div>
            </div>
        );
    }
}


ActivityDetails.defaultProps = {
    activity: {},
};

ActivityDetails.propTypes = {
    activity: PropTypes.object,
};

export default ActivityDetails;
